import { SubscriptionState } from "graphql/generated/types";
import { TNumberMin } from "graphqlQueries";
import { NumberType } from "graphqlQueries/generated/globalTypes";

export const numberHasActiveSubscription = (
  number?: null | {
    subscription?: null | {
      state?: SubscriptionState | null;
    };
  }
) =>
  (number?.subscription && number.subscription.state && number.subscription.state !== SubscriptionState.CANCELED) ??
  false;

export const isSandbox = (number?: TNumberMin) => {
  if (!number?.numberType) {
    return true;
  }
  return number?.numberType === NumberType.VIRTUAL_NUMBER || number?.numberType === NumberType.VIRTUAL_NUMBER_PROVIDER;
};
